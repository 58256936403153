@import 'reset';
@import 'header';
@import 'about';
@import 'services';
@import 'footer';
@import 'contact';
@import 'nav';
@import 'aos';
@import 'variables';

html,
body {
    overflow-x: hidden;
}

body {
    font-family: $font-main;
    background-color: #fff;
    width: 100vw;
    scroll-behavior: smooth;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
}

.center {
    text-align: center;
}

.accent {
    color: $accent-col;
}

.bold {
    font-weight: bold;
}
